<template>
	<b-card>
		<b-row class="m-1 top-header">
			<b-col cols="12" md="6" class="d-flex align-items-center">
				<h3 class="mb-0">Devices</h3>
			</b-col>
			<b-col
				cols="12"
				md="6"
				class="d-flex justify-content-end align-items-center"
			>
				<b-button variant="primary" v-b-modal.add-device class="mr-1 ml-2">
					Add
				</b-button>
			</b-col>
		</b-row>
		<div v-if="deviceList && deviceList.length > 0">
			<div v-for="device in deviceList" :key="device.id">
				<div class="device-item">
					<div class="d-flex align-items-center justify-content-between">
						<div class="">
							<div>{{ device.device_id }}</div>
						</div>
						<b-form-checkbox
							v-model="device.device_status"
							value="Active"
							unchecked-value="InActive"
							name="status"
							switch
							inline
							@change="changeDeviceStatus(device.device_id)"
						>
						</b-form-checkbox>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img
				:src="
					require('@/assets/images/svg/infographics/no_device_infographic.svg')
				"
				class="mr-2"
			/>
			<h4 class="mt-2 Secondary">No device added yet</h4>
		</div>
		<b-modal
			id="add-device"
			title="Add Device"
			ok-only
			ok-title="Add"
			@ok="addDevice"
			ref="my-modal"
		>
			<b-form method="post">
				<b-form-group label="Enter Device Id">
					<div class="form-label-group">
						<b-form-input
							name="deviceId"
							v-model="deviceId"
							placeholder="Enter Device Id"
							type="text"
						/>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>

		<!-- confirmation modal -->
		<b-modal
			id="modal-confirmation"
			title="Add Device"
			ok-only
			class="primary badge-light-success p-1"
			ok-title="Add"
			@ok="() => attachDevice()"
		>
			<b-card-text>
				Are you sure, you want to attach this device ?
			</b-card-text>
		</b-modal>
	</b-card>
</template>

<script>
import {
	BCard,
	BRow,
	BImg,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BFormCheckbox,
} from "bootstrap-vue";

import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BCard,
		BRow,
		BImg,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		BFormCheckbox,
	},
	data() {
		return {
			deviceList: [],
			deviceId: null,
			device_status: null,
		};
	},
	created: function () {
		this.getDeviceList();
	},
	methods: {
		moment() {
			return moment();
		},
		getDeviceList() {
			const hatchery_id = this.$route.params.hatcheryId;
			axiosIns
				.get(`web/hatchery/${hatchery_id}/get-device-lists`)
				.then((response) => {
					this.deviceList = response.data.device_list;
				})
				.catch((error) => {
					error;
				});
		},
		addDevice() {
			const hatchery_id = this.$route.params.hatcheryId;
			var data = new FormData();
			const machineId = this.$route.params.hatchId;
			data.append("deviceId", this.deviceId);
			axiosIns
				.post(`web/hatchery/${hatchery_id}/add-device`, data)
				.then((res) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `data Successfully Added `,
						},
					});
					this.getDeviceList();
				})

				.catch((error) => {
					error.data;
				});
		},
		changeDeviceStatus(id) {
			const hatchery_id = this.$route.params.hatcheryId;
			var data = new FormData();
			data.append("deviceId", id);
			axiosIns
				.post(`web/hatchery/${hatchery_id}/change-device-status`, data)
				.then((res) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Device status changed successfully `,
						},
					});
					this.getDeviceList();
				})

				.catch((error) => {
					error.data;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.device-item {
	border: 1px solid #ebe9f1;
	border-radius: 0.357rem;
	padding: 1rem 1.5rem 1rem 1.3rem;
	margin: 1rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
